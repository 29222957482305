import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useFormik } from 'formik';
import { INewOpportunityForm, IOpportunity, OpportunityLocationType } from 'global/interfaces/opportunity';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { nameof } from 'ts-simple-nameof';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from 'contexts/AuthContext';

import { opportunityValidationSchema } from 'global/validations/opportunity';
import { postNewOpportunity, updateOpportunity, updateOpportunityForConversion } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useNavigate } from 'react-router-dom';
import CategoryMultiSelect from 'components/common/Select/CategoryMultiSelect';

import { pushToDataLayer } from 'utils/tagHelper';
import { postOpportunity } from 'global/constants';

import { UserRole } from 'global/enums/userRole';
import { useLocalization } from 'global/hooks/useLocalization';

const StyledFormGrid = styled(Grid)(() => ({
  marginTop: '-16px',
}));

const StyledButtonGrid = styled(Grid)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: '14px',
  color: theme.palette.error.main,
}));

export default function OpportunityForm({
  setSubmitted,
  isExternal,
  opportunity,
  accessToken,
}: {
  setSubmitted: Dispatch<boolean>;
  isExternal: boolean;
  opportunity?: IOpportunity;
  accessToken?: string;
}) {
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.user !== null;

  const hasAdminRole = authContext.user && authContext.user.roles.indexOf(UserRole.Administrator) > -1;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { localCurrencySymbol } = useLocalization(29);

  const handleCancelClick = (): void => {
    navigate(-1);
  };

  const form = useFormik<INewOpportunityForm>({
    initialValues: {
      name: opportunity?.name ?? '',
      description: opportunity?.description ?? '',
      email: opportunity?.email ?? '',
      title: opportunity?.title ?? '',
      budget: opportunity?.budget ?? '',
      token: '',
      userId: opportunity?.userId ?? undefined,
      isExternal: isExternal,
      applyInstructions: opportunity?.applyInstructions ?? undefined,
      categories: opportunity?.categories ?? [],
      locationType: opportunity?.locationType ?? OpportunityLocationType.Remote,
      location: opportunity?.location ?? undefined,
      isFree: opportunity?.isFree ?? false,
      isAssisted: opportunity?.isAssisted ?? false,
      assistedEmail: opportunity?.assistedEmail ?? undefined,
    },
    onSubmit: values => {
      setLoading(true);
      if (!opportunity) {
        postNewOpportunity(values)
          .then(() => {
            if (!isExternal) {
              pushToDataLayer(postOpportunity);
            }
            setSubmitted(true);
          })
          .catch(showError)
          .finally(() => setSubmitted(true));
      }

      if (opportunity && accessToken) {
        updateOpportunityForConversion(values, opportunity.id, accessToken)
          .catch(showError)
          .finally(() => setSubmitted(true));
      } else if (opportunity) {
        updateOpportunity(values, opportunity.id)
          .catch(showError)
          .finally(() => setSubmitted(true));
      }
    },
    validationSchema: opportunityValidationSchema(!isLoggedIn && !accessToken),
  });

  const scrollToFirstError = () => {
    const el = document.querySelector('.Mui-error, [data-error]');
    (el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useEffect((): void => {
    if (form.isSubmitting && !form.isValid) {
      scrollToFirstError();
    }
  }, [form.isSubmitting]);

  useEffect(() => {
    if (isLoggedIn) {
      if (form.values.name == '') {
        form.setFieldValue(
          nameof<INewOpportunityForm>(x => x.name),
          authContext.user?.firstName,
        );
      }
      if (form.values.email == '') {
        form.setFieldValue(
          nameof<INewOpportunityForm>(x => x.email),
          authContext.user?.email,
        );
      }
      form.setFieldValue(
        nameof<INewOpportunityForm>(x => x.userId),
        authContext.user?.id,
      );
    }
  }, [authContext.user]);

  return (
    <form onSubmit={form.handleSubmit}>
      <StyledFormGrid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>Basic Info</Typography>
        </Grid>
        {!isExternal && (
          <>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControl>
                  <TextField
                    name={nameof<INewOpportunityForm>(x => x.name)}
                    label="Your Name *"
                    value={form.values.name}
                    variant="outlined"
                    fullWidth
                    onChange={form.handleChange}
                    error={form.touched.name !== undefined && Boolean(form.errors.name)}
                    helperText={form.touched.name !== undefined ? form.errors.name : ''}
                    disabled={isLoggedIn}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControl>
                  <TextField
                    name={nameof<INewOpportunityForm>(x => x.email)}
                    label="Email *"
                    variant="outlined"
                    fullWidth
                    value={form.values.email}
                    onChange={form.handleChange}
                    error={form.touched.email !== undefined && Boolean(form.errors.email)}
                    helperText={form.touched.email !== undefined ? form.errors.email : ''}
                    disabled={isLoggedIn}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12}>
          <FormGroup>
            <FormControl>
              <TextField
                name={nameof<INewOpportunityForm>(x => x.title)}
                label="Title *"
                variant="outlined"
                fullWidth
                value={form.values.title}
                placeholder="i.e Freelance Designer for new Saas Product"
                onChange={form.handleChange}
                error={form.touched.title !== undefined && Boolean(form.errors.title)}
                helperText={form.touched.title !== undefined ? form.errors.title : ''}
              />
            </FormControl>
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'h6'} mt={2}>
            Project Details
          </Typography>
          <Typography variant="body2" mt="4px">
            Please describe the freelancer&apos;s responsibilities, your niche, expected deliverables, and timeline.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControl>
              <TextField
                name={nameof<INewOpportunityForm>(x => x.description)}
                variant="outlined"
                fullWidth
                rows={10}
                multiline
                onChange={form.handleChange}
                value={form.values.description}
                error={form.touched.description !== undefined && Boolean(form.errors.description)}
                helperText={form.touched.description !== undefined ? form.errors.description : ''}
              />
            </FormControl>
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'h6'} mt={2}>
            Categories
          </Typography>
          <Typography variant="body2" mt="4px">
            Please choose 1 or more categories that best describe the project.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CategoryMultiSelect
            value={form.values.categories}
            homeCategoriesOnly={false}
            label=""
            onChange={changedCategories =>
              form.setFieldValue(
                nameof<INewOpportunityForm>(x => x.categories),
                changedCategories.target.value,
              )
            }
            input={<OutlinedInput id="select-multiple-chip" label="Categories" />}
            error={form.touched.categories !== undefined && Boolean(form.errors.categories)}
            helpertext={form.errors.categories ?? ''}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'h6'} mt={2}>
            Remote?
          </Typography>
          <Typography variant="body2" mt="4px">
            Is this opportunity fully remote and accepts candidates worldwide?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            name={nameof<INewOpportunityForm>(x => x.locationType)}
            value={form.values.locationType}
            onChange={form.handleChange}
            row
          >
            <FormControlLabel value={OpportunityLocationType.Remote} control={<Radio />} label="Yes" />
            <FormControlLabel value={OpportunityLocationType.Local} control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText error>{form.errors.locationType}</FormHelperText>
        </Grid>
        {form.values.locationType == OpportunityLocationType.Local && (
          <>
            <Grid item xs={12}>
              <Typography variant={'h6'} mt={2}>
                Location
              </Typography>
              <Typography variant="body2" mt="4px">
                Please enter location and remote/hybrid nature. Examples: Remote (UK), Hybrid (New York, US) or On-site
                (Manchester, UK).
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormControl>
                  <TextField
                    name={nameof<INewOpportunityForm>(x => x.location)}
                    label="Location *"
                    variant="outlined"
                    fullWidth
                    value={form.values.location}
                    placeholder="i.e City"
                    onChange={form.handleChange}
                    error={form.touched.location !== undefined && Boolean(form.errors.location)}
                    helperText={form.touched.location !== undefined ? form.errors.location : ''}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant={'h6'} mt={2}>
            Budget
          </Typography>
          <Typography variant="body2" mt="4px">
            Please indicate an approx budget or rate for this project including the currency. A range can be used.
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <FormGroup>
            <FormControl>
              <TextField
                name={nameof<INewOpportunityForm>(x => x.budget)}
                label={isExternal ? 'Budget' : 'Budget *'}
                variant="outlined"
                fullWidth
                placeholder={`i.e. ${localCurrencySymbol}750, ${localCurrencySymbol}1 per word, ${localCurrencySymbol}1000 per month, ${localCurrencySymbol}500-${localCurrencySymbol}750`}
                value={form.values.budget}
                onChange={form.handleChange}
                error={form.touched.budget !== undefined && Boolean(form.errors.budget)}
                helperText={form.touched.budget !== undefined ? form.errors.budget : ''}
              />
            </FormControl>
          </FormGroup>
        </Grid>

        {isExternal && (
          <>
            <Grid item xs={12}>
              <Typography variant={'h6'} mt={2}>
                Apply Instructions
              </Typography>
              <Typography variant="body2" mt="4px">
                A url that starts with http or text that describes how to apply.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormControl>
                  <TextField
                    name={nameof<INewOpportunityForm>(x => x.applyInstructions)}
                    label=""
                    variant="outlined"
                    fullWidth
                    value={form.values.applyInstructions}
                    placeholder="https://...."
                    onChange={form.handleChange}
                    error={form.touched.applyInstructions !== undefined && Boolean(form.errors.applyInstructions)}
                    helperText={form.touched.applyInstructions !== undefined ? form.errors.applyInstructions : ''}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </>
        )}

        {hasAdminRole && (opportunity || isExternal) && (
          <>
            <Grid item xs={12}>
              <Typography variant={'h6'} mt={2}>
                Is Free
              </Typography>
              <Typography variant="body2" mt="4px">
                Toggle this switch on to make the job free.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormControl>
                  <Switch
                    name="isFree"
                    checked={form.values.isFree}
                    sx={{ ml: '-8px', mt: '-6px' }}
                    onChange={form.handleChange}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </>
        )}

        {hasAdminRole && isExternal && (
          <>
            <Grid item xs={12}>
              <Typography variant={'h6'} mt={2}>
                Is Assisted
              </Typography>
              <Typography variant="body2" mt="4px">
                Toggle this switch on to collect applications on Shoutt. Applications are forwarded to the Assisted
                Email address.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormControl>
                  <Switch
                    name="isAssisted"
                    checked={form.values.isAssisted}
                    sx={{ ml: '-8px', mt: '-6px' }}
                    onChange={form.handleChange}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </>
        )}

        {hasAdminRole && isExternal && form.values.isAssisted && (
          <>
            <Grid item xs={12}>
              <Typography variant={'h6'} mt={2}>
                Assisted Email Address
              </Typography>
              <Typography variant="body2" mt="4px">
                Please enter the email address that job applications will be sent to.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormControl>
                  <TextField
                    name={nameof<INewOpportunityForm>(x => x.assistedEmail)}
                    label={'Assisted Email *'}
                    variant="outlined"
                    fullWidth
                    value={form.values.assistedEmail}
                    onChange={form.handleChange}
                    error={form.touched.assistedEmail !== undefined && Boolean(form.errors.assistedEmail)}
                    helperText={form.touched.assistedEmail !== undefined ? form.errors.assistedEmail : ''}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </>
        )}

        {!isLoggedIn && !accessToken && (
          <Grid item>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
              onChange={(value: string | null) => {
                form.setFieldValue(
                  nameof<INewOpportunityForm>(x => x.token),
                  value,
                );
              }}
              onErrored={() => console.error('reCAPTCHA initialization error')}
            />
            {form.errors.token && form.submitCount > 0 && <StyledHelperText>{form.errors.token}</StyledHelperText>}
          </Grid>
        )}

        <StyledButtonGrid container item xs={12} justifyContent={'flex-end'}>
          <Grid container item xs={12} sm={6} spacing={1}>
            <Grid item xs={6}>
              <RoundButton variant="outlined" fullWidth onClick={handleCancelClick}>
                Cancel
              </RoundButton>
            </Grid>
            <Grid item xs={6}>
              <RoundButton loading={loading} variant="contained" type="submit" fullWidth>
                Submit
              </RoundButton>
            </Grid>
          </Grid>
        </StyledButtonGrid>
      </StyledFormGrid>
    </form>
  );
}
