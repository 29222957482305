import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IOpportunity } from 'global/interfaces/opportunity';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getOpportunityForConversion } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import im from 'assets/images/lps/Hero_submit_opportunity_min.webp';
import { useLocalization } from 'global/hooks/useLocalization';
import NewOpportunityStepCarousel from 'components/opportunities/newOpportunityStepCarousel';
import { BannerStack, FeatureSubText, FeatureText, MultiLineFeatureContainer } from 'components/info/Hero';
import { BriefIcon } from 'components/icon/BriefIcon';
import { ClockIcon } from '@mui/x-date-pickers';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import NewOpportunitySteps from 'components/opportunities/newOpportunitySteps';
import OpportunityForm from 'components/opportunities/OpportunityForm';
import { NewOpportunityConfirmation } from 'components/opportunities/newOpportunityConfirmation';
import OppNoAccess from './OppNoAccess';
import OppConverted from './OppConverted';
import OppNotFound from './OppNotFound';

const StyledBannerBox = styled(Box)(() => ({
  backgroundImage: `url(${im})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '72px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '32px',
  },
}));

export function ConvertOpportunity() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [opp, setOpp] = useState<IOpportunity>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol } = useLocalization(29);
  const [searchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const [converted, setConverted] = useState(false);
  const [noAcess, setNoAccess] = useState(false);

  useTitle('Convert Opportunity');

  useEffect(() => {
    if (!id) return;

    getOpportunityForConversion(id, searchParams.get('accessToken'))
      .then(res => setOpp(res))
      .catch(e => {
        if (e.status === 400) {
          setConverted(true);
          return;
        }
        if (e.status === 401) {
          setNoAccess(true);
          return;
        }
        if (e.status === 404) {
          return;
        }
        showError(e);
      })
      .finally(() => setLoading(false));
  }, []);

  if (!loading && converted) {
    return <OppConverted />;
  }

  if (!loading && noAcess) {
    return <OppNoAccess />;
  }

  if (!loading && !opp) {
    return <OppNotFound />;
  }

  return (
    <>
      {!submitted ? (
        <>
          {opp ? (
            <>
              {isMobile && <NewOpportunityStepCarousel currencySymbol={localCurrencySymbol} />}
              <StyledBannerBox>
                <Container maxWidth="lg">
                  <BannerStack direction={'column'} rowGap={2}>
                    <Grid container columnSpacing={6}>
                      <Grid item xs={12} md={8}>
                        <Typography
                          variant="h3"
                          fontWeight={700}
                          fontSize={{ xs: '26px', md: '44px', lg: '55px' }}
                          color={'#101828'}
                          maxWidth={'800px'}
                        >
                          Turn your opportunity into a Shoutt Exclusive for FREE
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize={{ xs: '16px', lg: '20px' }}
                          color={'#344054'}
                          maxWidth={'800px'}
                          mt={isMobile ? 2 : 3}
                        >
                          Get more pre-screened applicants by turning your opportunity into a Shoutt Exclusive in just a
                          few clicks. No hidden costs or obligations.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mt={{ xs: '0px', md: '0px' }} spacing={isMobile ? '14px' : '22px'}>
                      <Grid item xs={12} md={4}>
                        <MultiLineFeatureContainer direction={'row'}>
                          <Stack direction={'column'} gap={'4px'} spacing={1}>
                            <Stack direction={'row'} spacing={1}>
                              <ExclusiveIcon
                                sx={{
                                  width: !isMobile ? '24px' : '20px',
                                  height: !isMobile ? '24px' : '20px',
                                }}
                                htmlColor="#ED79D5"
                              />

                              <FeatureText>Vetted Applicants Only</FeatureText>
                            </Stack>

                            <FeatureSubText>
                              We promote Shoutt exclusives on our platform and on Social Media so you get more
                              applicants.
                            </FeatureSubText>
                          </Stack>
                        </MultiLineFeatureContainer>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MultiLineFeatureContainer direction={'row'}>
                          <Stack direction={'column'} gap={'4px'} spacing={1}>
                            <Stack direction={'row'} spacing={1}>
                              <ClockIcon
                                sx={{
                                  width: !isMobile ? '24px' : '20px',
                                  height: !isMobile ? '24px' : '20px',
                                }}
                                htmlColor="#ED79D5"
                              />
                              <FeatureText>Share & Collaborate</FeatureText>
                            </Stack>

                            <FeatureSubText>
                              Share your list of applicants with colleagues. Or share the opportunity yourself and we’ll
                              handle the vetting.
                            </FeatureSubText>
                          </Stack>
                        </MultiLineFeatureContainer>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MultiLineFeatureContainer direction={'row'} height={'100%'}>
                          <Stack direction={'column'} gap={'4px'} spacing={1}>
                            <Stack direction={'row'} spacing={1}>
                              <BriefIcon
                                sx={{
                                  width: !isMobile ? '24px' : '20px',
                                  height: !isMobile ? '24px' : '20px',
                                }}
                                htmlColor="#ED79D5"
                              />
                              <FeatureText>Secure Payments</FeatureText>
                            </Stack>

                            <FeatureSubText>
                              Take advantage of our commission free payment protection tools. Or deal direct. It’s your
                              choice.
                            </FeatureSubText>
                          </Stack>
                        </MultiLineFeatureContainer>
                      </Grid>
                    </Grid>
                  </BannerStack>
                </Container>
              </StyledBannerBox>
              <StyledContainer maxWidth={'lg'}>
                <Grid container>
                  {!isMobile && (
                    <Grid item xs={0} md={4}>
                      <NewOpportunitySteps currencySymbol={localCurrencySymbol} />
                    </Grid>
                  )}

                  <Grid container item xs={12} md={8}>
                    <OpportunityForm
                      setSubmitted={setSubmitted}
                      isExternal={false}
                      opportunity={opp}
                      accessToken={searchParams.get('accessToken') ?? ''}
                    />
                  </Grid>
                </Grid>
              </StyledContainer>
            </>
          ) : (
            <StyledContainer>
              <Box sx={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
                <CircularProgress />
              </Box>
            </StyledContainer>
          )}
        </>
      ) : (
        <NewOpportunityConfirmation />
      )}
    </>
  );
}
