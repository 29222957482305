import { IChatFile } from 'global/interfaces/chatMessage';
import { handleAxiosError } from 'utils/errorHandler';
import { authorizedPost } from './baseApiService';
import authApi from './apiService';

import { createFileFormData } from 'utils/file';
import { IFileMetadata } from 'global/interfaces/file';

const resource = 'Storage';

export async function saveImage(file: File): Promise<IFileMetadata> {
  const formData = createFileFormData(file);
  return await initFilePost<IFileMetadata>(formData, '/profileImages');
}

export async function saveLogo(file: File): Promise<IFileMetadata> {
  const formData = createFileFormData(file);
  return await initFilePost<IFileMetadata>(formData, '/logo');
}

export async function saveBusinessIcon(file: File): Promise<IFileMetadata> {
  const formData = createFileFormData(file);
  return await initFilePost<IFileMetadata>(formData, '/businessicon');
}

export async function savePortfolioFile(file: File, controller: AbortController): Promise<IFileMetadata> {
  const formData = createFileFormData(file);
  return await initFilePost<IFileMetadata>(formData, '/portfolioFiles', controller);
}

export async function saveOpportunityFile(
  file: File,
  controller: AbortController,
  opportunityId: string,
): Promise<IFileMetadata> {
  const formData = createFileFormData(file);
  return await initFilePost<IFileMetadata>(formData, `/opportunityFiles/${opportunityId}`, controller);
}

export async function createChatFileUrl(file: IChatFile): Promise<string> {
  return await authorizedPost<string>(`${resource}/CreateChatFileUrl`, {
    chatThreadId: file.chatThreadId,
    fileName: file.fileName,
    extension: file.extension,
  });
}

export async function saveChatFile(
  upload: File,
  chatThreadId: string,
  controller: AbortController,
): Promise<IChatFile> {
  const formData = new FormData();
  formData.append('upload.file', upload);
  formData.append('upload.chatThreadId', chatThreadId);

  return await initFilePost<IChatFile>(formData, '/SaveChatFile', controller);
}

async function initFilePost<T>(
  formData: FormData | object,
  route = '',
  controller: AbortController | undefined = undefined,
): Promise<T> {
  return await new Promise((resolve, reject) => {
    authApi
      .post(`${resource}${route}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: controller?.signal,
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(handleAxiosError(error));
      });
  });
}
