import { Chip, CircularProgress, useTheme } from '@mui/material';
import IApiError from 'global/interfaces/api';
import { IFileMetadata } from 'global/interfaces/file';
import { IUploadQueue } from 'global/interfaces/user';
import { useEffect } from 'react';
import { showError } from 'utils/errorHandler';

interface IUploadChipProps {
  upload: IUploadQueue;
  onUploadCancel: (tempId: string) => void;
  onUploadComplete: (tempId: string, response: IFileMetadata) => void;
  uploadFunc: (file: File, controller: AbortController) => Promise<IFileMetadata>;
}

export default function UploadChip(props: IUploadChipProps) {
  const theme = useTheme();

  useEffect(() => {
    let controller: AbortController | null = new AbortController();
    props
      .uploadFunc(props.upload.file, controller)
      .then((fileResponse: IFileMetadata) => {
        controller = null;
        props.onUploadComplete(props.upload.tempId, fileResponse);
      })
      .catch((err: IApiError) => {
        props.onUploadCancel(props.upload.tempId);
        if (err.message !== 'canceled') {
          showError(err);
        }
      });
  }, []);

  return (
    <Chip
      variant="outlined"
      label={props.upload.file.name}
      sx={{
        backgroundColor: theme.palette.grey[200],
        borderColor: theme.palette.grey[200],
      }}
      onDelete={() => undefined}
      deleteIcon={<CircularProgress size={16} />}
    />
  );
}
