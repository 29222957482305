import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import InactiveDiscountDetails from './inactiveDiscountDetails';
import { UserSubscription } from 'global/interfaces/userSubscription';
import { ActiveDiscountDetails } from './activeDiscountDetails';

export default function CancelSubscriptionDialog({
  open,
  handleClose,
  subscription,
  loadSubscription,
}: {
  open: boolean;
  handleClose: () => void;
  subscription: UserSubscription | undefined;
  loadSubscription: () => void;
}) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={!loading ? handleClose : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
    >
      {!subscription?.discountApplied && (!subscription?.cancelAttempt || subscription.cancelAttempt < 3) ? (
        <ActiveDiscountDetails
          loading={loading}
          setLoading={setLoading}
          loadSubscription={loadSubscription}
          handleClose={handleClose}
          subscription={subscription}
        />
      ) : (
        <InactiveDiscountDetails
          loading={loading}
          setLoading={setLoading}
          loadSubscription={loadSubscription}
          handleClose={handleClose}
          subscription={subscription}
        />
      )}
    </Dialog>
  );
}
