import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import ItemConnections from './ItemConnections';
import ItemActionRequired from './ItemActionRequired';
import BiteSizeTips from './BiteSizeTips/BiteSizeTips';
import ItemLatestOpportunities from './ItemLatestOpportunities';
import ItemMyPostedOpportunities from './ItemMyPostedOpportunities';

export default function MainSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container mt={2} spacing={2}>
      {isMobile && (
        <Grid item container xs={12} md={4}>
          <Stack direction="column" rowGap={2} width="100%">
            <ItemActionRequired />
          </Stack>
        </Grid>
      )}
      <Grid item container xs={12} md={4}>
        <Stack direction="column" rowGap={2} width="100%">
          <ItemMyPostedOpportunities />
          <ItemLatestOpportunities />
        </Stack>
      </Grid>

      {!isMobile && (
        <Grid item container xs={12} md={4}>
          <Stack direction="column" rowGap={2} width="100%">
            <ItemActionRequired />
            <ItemConnections />
          </Stack>
        </Grid>
      )}
      {isMobile && (
        <Grid item container xs={12} md={4}>
          <Stack direction="column" rowGap={2} width="100%">
            <ItemConnections />
          </Stack>
        </Grid>
      )}

      <Grid item container xs={12} md={4}>
        <BiteSizeTips />
      </Grid>
    </Grid>
  );
}
