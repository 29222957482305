import { Box } from '@mui/material';
import { NewOpportunityConfirmation } from 'components/opportunities/newOpportunityConfirmation';
import OpportunityPage from 'components/opportunities/OpportunityPage';
import { useState } from 'react';
import { useTitle } from 'utils/router';

export default function NewJob() {
  useTitle('Post an Opportunity');
  const [submitted, setSubmitted] = useState(false);
  return (
    <>
      <Box bgcolor={!submitted ? '#F8F7F4' : '#FFFFFF'}>
        {!submitted ? (
          <OpportunityPage setSubmitted={setSubmitted} isExternal={false} />
        ) : (
          <NewOpportunityConfirmation />
        )}
      </Box>
    </>
  );
}
