import { Stack, styled } from '@mui/material';

export const DetailContainer = styled(Stack)(({ theme }) => ({
  maxWidth: '75%',
  gap: '18px',
  margin: 'auto',
  paddingTop: '70px',
  paddingBottom: '70px',

  [theme.breakpoints.down('md')]: {
    gap: '12px',
    maxWidth: 'unset',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
}));
