import { Grid, styled, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { getSteps, StyledCheckBox, StyledSmallTypography, StyledSubHeadingTypography } from './newOpportunitySteps';

export const StyledContainer = styled(Grid)(() => ({
  padding: '20px',
  backgroundColor: '#E7E5E0',
}));

interface NewOpportunityStepCarouselProps {
  currencySymbol: string;
}

export default function NewOpportunityStepCarousel({ currencySymbol }: NewOpportunityStepCarouselProps) {
  const steps = getSteps(currencySymbol);
  return (
    <StyledContainer item xs={12}>
      <Carousel autoPlay={true} indicators={false} interval={3000}>
        {steps.map((op, index) => (
          <Grid container item xs={12} key={index}>
            <StyledCheckBox>
              <Typography textAlign={'center'} variant="caption" color={'white'} fontWeight={800} pl="3px" pt="1px">
                {index + 1}
              </Typography>
            </StyledCheckBox>
            <StyledSubHeadingTypography variant="subtitle2">{op.heading}</StyledSubHeadingTypography>
            <StyledSmallTypography variant="caption">{op.text}</StyledSmallTypography>
          </Grid>
        ))}
      </Carousel>
    </StyledContainer>
  );
}
