import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DetailContainer } from 'components/terms/Containers';
import { useTitle } from 'utils/router';

import HeroSection from 'components/info/Hero';
import personIm from 'assets/images/lps/personimage5.webp';

export default function About(): JSX.Element {
  useTitle('About Us');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <HeroSection
        title={'About us'}
        subtext="Shoutt is a commission free platform for freelance work. Hire or get hired across creative, marketing and
              technical fields with next level ease and simplicity."
        minHeight={isMobileScreen ? '260px' : '470px'}
        personImage={personIm}
      />
      <Box bgcolor="#F8F7F4">
        <Container maxWidth="lg">
          <DetailContainer>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'} mb={3}>
              No race to the bottom. No hefty commissions. A lot less noise.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              We’re a small team of engineers and marketers from the UK that wanted to do something better as a result
              of our own experiences and frustrations.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              We help freelancers and businesses by simplifying the way they collaborate, encouraging strong
              relationships to be built, rather than just pushing ‘gigs’.
            </Typography>

            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Everything is vetted — both talent and opportunities — so less time is wasted and the needle moves faster.
              Everything is designed with quality and simplicity in mind.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Our payment and contract tools encourage clarity and help to avoid headaches down the road. And when you
              want to bypass the platform and work directly, you can.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              We’re always adding new features to help you work smarter, with AI tools in the works to help both
              businesses and freelancers grow faster.
            </Typography>

            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              With a free option and premium features available for an affordable monthly fee, Shoutt makes quality work
              happen without the fuss.
            </Typography>
          </DetailContainer>
        </Container>
      </Box>
    </>
  );
}
