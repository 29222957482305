import { Badge, IconButton, styled } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

export const HeaderLogo = styled('img')(({ theme }) => ({
  height: theme.spacing(4.5),
  marginRight: theme.spacing(2),
  filter: 'invert()',

  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    height: theme.spacing(4),
  },
}));

export const StyledHeaderIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: theme.spacing(2),

  '&.MuiSvgIcon-root': {
    fontSize: theme.spacing(3),
  },

  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(0),
  },
}));

export const StyledHeaderBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-weight: 500;
  }
`;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const drawerWidth = 240;

export const MainAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: 0,
  variants: [
    {
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));
