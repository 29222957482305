import { SvgIcon, SvgIconProps } from '@mui/material';

export const PersonIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.99955 12.5C7.35782 12.5 5.00855 13.7755 3.51288 15.755C3.19097 16.181 3.03002 16.394 3.03528 16.6819C3.03935 16.9043 3.17902 17.1849 3.35402 17.3222C3.58054 17.5 3.89444 17.5 4.52224 17.5H15.4769C16.1047 17.5 16.4186 17.5 16.6451 17.3222C16.8201 17.1849 16.9598 16.9043 16.9638 16.6819C16.9691 16.394 16.8081 16.181 16.4862 15.755C14.9906 13.7755 12.6413 12.5 9.99955 12.5Z"
      stroke={props.style?.color ?? '#6B7280'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.99955 10C12.0706 10 13.7496 8.32107 13.7496 6.25C13.7496 4.17893 12.0706 2.5 9.99955 2.5C7.92848 2.5 6.24955 4.17893 6.24955 6.25C6.24955 8.32107 7.92848 10 9.99955 10Z"
      stroke={props.style?.color ?? '#6B7280'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
