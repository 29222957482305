import { Box, CircularProgress, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import {
  ApplyInstructionsType,
  ApplyStageType,
  IOppApplyExternalReponse,
  IOpportunity,
} from 'global/interfaces/opportunity';
import { RoundButton } from 'components/common/Button/RoundButton';

import { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { applied, applyToExternal, notInterested, profileNotApproved } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { pushToDataLayer } from 'utils/tagHelper';
import { appliedOpportunity } from 'global/constants';
import { AuthContext } from 'contexts/AuthContext';
import { VettingStatus } from 'global/enums/vettingStatus';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

interface IApplyExternalOpportunity {
  onClose: () => void;
  open: boolean;
  opportunity: IOpportunity | null;
  updateApplyStageOnClose: (newStage: ApplyStageType) => void;
}

export default function ApplyExternalOpportunity(props: IApplyExternalOpportunity): JSX.Element {
  const theme = useTheme();
  const authContext = useContext(AuthContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [externalApplyInfo, setExternalApplyInfo] = useState<IOppApplyExternalReponse | null>(null);

  //JE: If someone is paying then they are allowed to apply for external opps
  const status: VettingStatus | undefined =
    authContext.user?.subscriptionActive ?? false ? VettingStatus.Approved : authContext.user?.vettingStatus;

  useEffect(() => {
    if (props.open && props.opportunity != null && status == VettingStatus.Approved) {
      setLoading(true);
      applyToExternal(props.opportunity.id)
        .then(res => {
          setExternalApplyInfo(res);
          setLoading(false);
        })
        .catch(showError);
      pushToDataLayer(appliedOpportunity, {
        transaction_id: props.opportunity.id,
      });
    } else if (props.open && props.opportunity != null && status != VettingStatus.Approved) {
      profileNotApproved(props.opportunity.id);
    }
  }, [props.opportunity, props.open]);

  const applyConfirmed = () => {
    if (props.opportunity?.id) {
      applied(props.opportunity?.id);
    }
    props.updateApplyStageOnClose(ApplyStageType.Applied);
  };

  const notInterestedConfirmed = () => {
    if (props.opportunity?.id) {
      notInterested(props.opportunity?.id);
    }
    props.updateApplyStageOnClose(ApplyStageType.NotInterested);
  };

  return (
    <>
      {status != VettingStatus.Approved && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              {status != VettingStatus.Rejected ? 'Get listed' : 'Profile rejected'}
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          {status === VettingStatus.NotSubmitted && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  To apply for this opportunity you must be listed on our marketplace. This ensures that only quality
                  vetted freelancers can respond to these opportunities.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Cancel
                </RoundButton>
                <RoundButton
                  variant="contained"
                  fullWidth={isMobileScreen}
                  onClick={() => navigate(`/users/${authContext.user?.id}/edit?apply=true`)}
                >
                  Get Listed
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {status === VettingStatus.Rejected && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  Your profile was rejected so you are not able to apply for this opportunity.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Close
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {status === VettingStatus.InProgress && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  You have submitted your profile for approval and it is currently being reviewed. You will be able to
                  apply for this opportunity when your profile is approved.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Close
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {status === VettingStatus.UpdatesRequired && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  To apply for this opportunity you must be listed on our marketplace. This ensures that only quality
                  vetted freelancers can respond. You have submitted for approval and have been requested to make the
                  following updates:
                </Typography>
                <Typography variant="body1" mt={2} fontStyle={'italic'}>
                  {authContext.user?.feedback}
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Cancel
                </RoundButton>
                <RoundButton
                  variant="contained"
                  fullWidth={isMobileScreen}
                  onClick={() => navigate(`/users/${authContext.user?.id}/edit`)}
                >
                  Update Profile
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
        </>
      )}
      {props.opportunity != null && status == VettingStatus.Approved && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              {props.opportunity.title}
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            {externalApplyInfo?.type == ApplyInstructionsType.Text && !loading && (
              <>
                <Typography variant="body1">
                  To apply for the opportunity &quot;{props.opportunity.title}&quot;, please follow the instructions
                  below. Don&apos;t forget to craft a concise and compelling response that addresses the brief, rather
                  than a generic intro. Make your message stand out from others!
                </Typography>
                <Typography variant="body1">{externalApplyInfo?.applyInstructions}</Typography>
                <Typography variant="subtitle1" fontWeight={600} mb={1}>
                  Did you apply?
                </Typography>
              </>
            )}
            {externalApplyInfo?.type == ApplyInstructionsType.Url && !loading && (
              <>
                <Typography variant="body1">
                  For this external opportunity please continue your application via the site where we sourced the
                  opportunity. Craft a concise and compelling response that addresses the brief, rather than a generic
                  intro. Make your message stand out from others!
                </Typography>

                <Link
                  to={externalApplyInfo?.applyInstructions ?? ''}
                  target="_blank"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Complete Application Now <OpenInNewOutlinedIcon sx={{ width: '18px', height: '18px', ml: 0.75 }} />
                </Link>
                <Typography variant="subtitle1" fontWeight={600} mb={1}>
                  Did you apply?
                </Typography>
              </>
            )}
            {loading && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <CircularProgress />
              </Box>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <RoundButton variant="outlined" onClick={notInterestedConfirmed} fullWidth>
                  No, not interested
                </RoundButton>
              </Grid>

              <Grid item xs={6}>
                <RoundButton variant="contained" onClick={applyConfirmed} fullWidth>
                  Yes, I&apos;ve applied
                </RoundButton>
              </Grid>
            </Grid>
          </StyledDialogContent>
        </>
      )}
    </>
  );
}
