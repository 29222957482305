import { Box, Container, Grid, Typography } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { redirectToLogin } from 'utils/router';

export default function OppNoAccess() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container maxWidth="md">
      <Grid item xs={12} md={6} mt={4}>
        <Typography textAlign="center" variant="h5">
          Unauthorised Access
        </Typography>
        {authContext.user ? (
          <Typography textAlign="center" variant="body1" pt={2}>
            You do not have permissions to view this opportunity. If you believe this is incorrect then please contact
            support@shoutt.co.
          </Typography>
        ) : (
          <Typography textAlign="center" variant="body1" pt={2}>
            You do not have permissions to view this opportunity. Please try to log in. If this still persists and you
            believe you should have access then please contact support@shoutt.co.
          </Typography>
        )}
        {!authContext.user && (
          <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 2 }}>
            <RoundButton variant="contained" onClick={() => redirectToLogin(navigate, location)}>
              Log In
            </RoundButton>
          </Box>
        )}
      </Grid>
    </Container>
  );
}
