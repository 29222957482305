import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTitle } from 'utils/router';

import OpportunitySubscribe from 'components/opportunities/newsletter/OpportunitySubscribe';
import ViewOpportunitiesComponent from 'components/opportunities/ViewOpportunitiesComponent';
import HeroSection, { IReview, IUsp } from 'components/info/Hero';
import { ClockIcon } from 'components/icon/ClockIcon';
import { BriefIcon } from 'components/icon/BriefIcon';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';

const ViewOpportunities = () => {
  useTitle('New Opportunities');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const review: IReview = {
    name: 'Imogen H',
    roleTitle: 'Marketing Manager',
    reviewText: '"I’ve landed two amazing long term clients!"',
  };

  const usps: IUsp[] = [
    {
      icon: (
        <ClockIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Save Precious Time',
      text: 'We spend 40 hours per week curating opportunities from 30+ sources, saving you time and money.',
    },
    {
      icon: (
        <BriefIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Simple and Convenient',
      text: 'Our live feed makes it fast and easy to browse opps in one place. All opportunities are max 7 days old.',
    },
    {
      icon: (
        <ExclusiveIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Free Profile',
      text: 'List your profile on our marketplace for free to be discovered by new clients, no bidding required.',
    },
  ];
  return (
    <>
      <Box sx={{ backgroundColor: '#F8F7F4', minHeight: '1000px' }}>
        <HeroSection title="1000+ curated freelance opportunities each month" review={review} usps={usps} />
        <OpportunitySubscribe />
        <Container maxWidth="lg">
          <ViewOpportunitiesComponent />
        </Container>
      </Box>
    </>
  );
};

export default ViewOpportunities;
