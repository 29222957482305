import { Box, Drawer, Grid, Stack, Typography, debounce, useMediaQuery, useTheme } from '@mui/material';
import { IFrontUserFilters } from 'global/interfaces/user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DeliveryLookups from 'json/DeliveryLookups.json';
import { useFormik } from 'formik';
import FilterSection from './FilterSection';
import { FilterIconButton } from 'components/common/Button/FilterIconButton';
import UserSearch from 'components/common/Search/UserSearch';

const defaultFilters = {
  category: undefined,
  maxDeliveryMethod: undefined,
  maxHourlyRate: undefined,
  search: '',
  country: undefined,
};

interface IUserFiltersProps {
  filters: IFrontUserFilters;
  onFilterChange: (filters: IFrontUserFilters) => void;
}

export const UserFilters = ({ onFilterChange, filters }: IUserFiltersProps) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const form = useFormik<IFrontUserFilters>({
    initialValues: filters,
    onSubmit: values => {
      onFilterChange(values);
    },
  });

  const debouncedSubmit = useCallback(
    debounce(() => form.submitForm(), 600),
    [form.submitForm],
  );

  useEffect(() => {
    if (form.initialValues?.maxHourlyRate != form.values?.maxHourlyRate && !isMobile) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, form.values.maxHourlyRate]);

  useEffect(() => {
    form.setValues({ ...filters });
  }, [filters]);

  useEffect(() => {
    if (form.initialValues != form.values && !isMobile) {
      form.submitForm();
    }
  }, [form.values.category, form.values.country, form.values.maxDeliveryMethod, form.values.onlySellingServices]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };

  const handleResetFilter = () => {
    form.resetForm({
      values: defaultFilters,
    });
    onFilterChange(defaultFilters);
  };

  const onlySellingServices = form.values.onlySellingServices ?? true;
  const isFilterApplied = useMemo(
    () =>
      Boolean(form.values.category) ||
      typeof form.values.maxDeliveryMethod === 'number' ||
      typeof form.values.maxHourlyRate === 'number',
    [form.values.category, form.values.maxDeliveryMethod, form.values.maxHourlyRate],
  );

  return (
    <>
      {isMobile ? (
        <Grid item xs={12}>
          <Box mb="12px">
            <UserSearch />
          </Box>

          <Stack direction="row" gap="8px" alignItems="center">
            <Typography variant={'h5'} color={isMobile ? 'white' : 'grey.800'} textAlign={'left'} whiteSpace={'nowrap'}>
              Search Results
            </Typography>

            <FilterIconButton isFilterApplied={isFilterApplied} onClick={toggleDrawer(true)} />
          </Stack>

          <Drawer
            anchor="bottom"
            open={isOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              style: {
                position: 'fixed',
                bottom: '0px',
                borderRadius: '10px 10px 0px 0px',
              },
            }}
          >
            <Grid item xs={12} padding="28px 24px 0px 24px">
              <FilterSection
                form={form}
                items={[{ id: '', label: 'None' }, ...DeliveryLookups]}
                onlySellingServices={onlySellingServices}
                toggleDrawer={toggleDrawer}
                handleResetFilter={handleResetFilter}
              />
            </Grid>
          </Drawer>
        </Grid>
      ) : (
        <Grid container alignItems="center">
          <FilterSection
            form={form}
            items={[{ id: '', label: 'None' }, ...DeliveryLookups]}
            onlySellingServices={onlySellingServices}
            toggleDrawer={toggleDrawer}
            handleResetFilter={handleResetFilter}
          />
        </Grid>
      )}
    </>
  );
};
