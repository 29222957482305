import { Chip, Stack, useTheme } from '@mui/material';
import { IFileMetadata } from 'global/interfaces/file';

interface IOppFileProps {
  files: IFileMetadata[];
  onDelete?: (path: string) => void;
}

export default function OpportunityFiles({ files, onDelete }: IOppFileProps) {
  const theme = useTheme();
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {files.map((item: IFileMetadata, i: number) => (
        <Chip
          key={i}
          variant="outlined"
          label={`${item.name}${item.extension}`}
          onDelete={() => onDelete && onDelete(item.id)}
          sx={{
            backgroundColor: theme.palette.grey[200],
            borderColor: theme.palette.grey[200],
          }}
        />
      ))}
    </Stack>
  );
}
