import EastIcon from '@mui/icons-material/East';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { AuthContext } from 'contexts/AuthContext';
import { applyOpportunityLogin } from 'global/constants';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { IOpportunity } from 'global/interfaces/opportunity';
import { SelectItem } from 'global/interfaces/selects';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOpportunities } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { redirectToSignUpSeller } from 'utils/router';
import { pushToDataLayer } from 'utils/tagHelper';
import ApplyDialog from './apply/ApplyDialog';
import { processOpportunityCategories } from './categories';
import OppFilters from './OppFilters';
import OpportunityAccordion from './OpportunityAccordion';
import { UserRole } from 'global/enums/userRole';

export default function ViewOpportunitiesComponent({ setNumOpps }: { setNumOpps?: Dispatch<number> }) {
  const [selectedOpportunity, setSelectedOpportunity] = useState<IOpportunity | null>(null);
  const [applyDialogOpen, setApplyDialogOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<SelectItem[]>([{ id: 'All', label: 'All' }]);
  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const [filteredOpps, setFilteredOpps] = useState<IOpportunity[]>([]);
  const [loading, setLoading] = useState(true);

  const authContext = useContext(AuthContext);
  const loggedIn: boolean = authContext.user !== null;

  const navigate = useNavigate();
  const location = useLocation();

  const processCategories = (retrievedOpps: IOpportunity[]) => {
    const items = processOpportunityCategories(retrievedOpps);
    setCategories(items);
  };

  const applyNow = (opportunity: IOpportunity): void => {
    setSelectedOpportunity(opportunity);
    if (!loggedIn) {
      pushToDataLayer(applyOpportunityLogin, {
        transaction_id: opportunity.id,
      });
      redirectToSignUpSeller(navigate, location);
    } else {
      setApplyDialogOpen(true);
    }
  };

  const onApplyClose = (): void => {
    setSelectedOpportunity(null);
    setApplyDialogOpen(false);
  };

  const updateOpportunity = (opportunity: IOpportunity): void => {
    setOpportunities(current => current.map(c => (c.id == opportunity.id ? opportunity : c)));
    setFilteredOpps(current => current.map(c => (c.id == opportunity.id ? opportunity : c)));
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewOpportunity],
    (res: IOpportunity) => {
      if (
        authContext.user?.subscriptionActive ||
        (authContext.user && authContext.user?.roles.indexOf(UserRole.Administrator) > -1)
      ) {
        setOpportunities(current => [res, ...current]);
      }
    },
    [],
  );

  useEffect(() => {
    processCategories(opportunities);
  }, [opportunities]);

  useEffect(() => {
    setLoading(true);
    getOpportunities()
      .then((retrievedOpps: IOpportunity[]) => {
        setOpportunities(retrievedOpps);
        setFilteredOpps(retrievedOpps);
        setLoading(false);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (opportunities && setNumOpps) {
      setNumOpps(opportunities.length);
    }
  }, [opportunities]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <OppFilters
            categories={categories}
            opportunities={opportunities}
            setFilteredOpps={setFilteredOpps}
            setLoading={setLoading}
          />
        </Grid>
        {loading && (
          <Grid item xs={12} sx={{ mb: 5 }} md={9}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="19vh" mt={{ xs: 0, md: 5 }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {!loading && filteredOpps.length > 0 && (
          <Grid item xs={12} sx={{ mb: 6 }} md={9}>
            <Box mt={{ xs: 0, md: 5 }}>
              <Typography variant="subtitle1" mb={2.25}>
                {filteredOpps.length} new {filteredOpps.length == 1 ? 'opportunity' : 'opportunities'}
              </Typography>

              {filteredOpps.map((opportunity: IOpportunity, index: number) => {
                //We unlock every third opportunity or lock them all if the list is 3 or less
                const lockableIndex = (index + 3) % 3 > 0 || filteredOpps.length < 4;
                //Never lock opportunities that are free to apply or if the user has a premium subscription
                const isOppLocked = !opportunity.isFree && !authContext.user?.subscriptionActive && lockableIndex;
                return (
                  <OpportunityAccordion
                    key={index}
                    opportunity={opportunity}
                    loggedIn={loggedIn}
                    updateOpportunity={updateOpportunity}
                    showTrackingIcon={true}
                    locked={isOppLocked}
                  >
                    <RoundButton
                      variant="contained"
                      endIcon={<EastIcon />}
                      onClick={() => {
                        applyNow(opportunity);
                      }}
                    >
                      {isOppLocked ? 'Unlock Now' : 'Apply Now'}
                    </RoundButton>
                  </OpportunityAccordion>
                );
              })}
            </Box>
          </Grid>
        )}
        {!loading && filteredOpps.length === 0 && (
          <Grid item xs={12} sx={{ mb: 6 }} md={9}>
            <Box mt={{ xs: 0, md: 5 }}>
              <Typography variant="subtitle1">No opportunities found</Typography>
              <Typography variant="body1" mt={2}>
                Sorry we couldn&apos;t find any opportunities that match your search criteria.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <ApplyDialog
        open={applyDialogOpen}
        opportunity={selectedOpportunity}
        onClose={onApplyClose}
        updateOpportunity={updateOpportunity}
      />
    </>
  );
}
