import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { AuthContext } from 'contexts/AuthContext';
import { format } from 'date-fns';
import { Dispatch, useContext } from 'react';
import { cancelSubscription } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { Close } from '@mui/icons-material';
import { UserSubscription } from 'global/interfaces/userSubscription';

interface IInactiveDiscountDetailsProps {
  loading: boolean;
  setLoading: Dispatch<boolean>;
  subscription: UserSubscription | undefined;
  loadSubscription: () => void;
  handleClose: () => void;
}

export default function InactiveDiscountDetails({
  setLoading,
  loadSubscription,
  handleClose,
  subscription,
  loading,
}: IInactiveDiscountDetailsProps) {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCancelSubscription = () => {
    setLoading(true);
    cancelSubscription()
      .then(() => {
        authContext.refresh().then(() => {
          loadSubscription();
          handleClose();
          setLoading(false);
        });
      })
      .catch(e => {
        showError(e);
        setLoading(false);
      });
  };

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          Downgrade to Free Plan
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        {subscription?.discountApplied && <Box>As a reminder you have 50% off next month.</Box>}
        By downgrading your premium plan will end on{' '}
        {subscription?.subscriptionCurrentPeriodEnd &&
          format(new Date(subscription.subscriptionCurrentPeriodEnd), 'MMMM d, yyyy')}
        . You can still use premium features until that date. Would you like to downgrade now?
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={handleClose} disabled={loading}>
          Close
        </RoundButton>
        <RoundButton
          variant="contained"
          fullWidth={isMobileScreen}
          onClick={handleCancelSubscription}
          type="submit"
          loading={loading}
        >
          Continue
        </RoundButton>
      </StyledDialogActions>
    </>
  );
}
