import { createTheme } from '@mui/material/styles';
import { desktopHeaderHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';
import im from 'assets/images/lps/img-bg-desktop-nav-header-generic.jpg';
import im2 from 'assets/images/lps/img-bg-mobile-nav-header.jpg';

declare module '@mui/material/styles' {
  interface Palette {
    yellow: Palette['primary'];
    blue: Palette['primary'];
    green: Palette['primary'];
    pink: Palette['primary'];
    violet: Palette['primary'];
    black: Palette['primary'];
  }
  interface PaletteOptions {
    yellow: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];
    violet: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
  }
  interface PaletteColor {
    50?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      '50': '#e9ecfc',
      '100': '#c7cff8',
      '200': '#a1aff4',
      '300': '#7890ef',
      '400': '#5675eb', //light
      '500': '#2d5be6', //main
      '600': '#2652db', //dark
      '700': '#1848ce',
      '800': '#043dc3',
      '900': '#0028b0',
      light: '#5675eb',
      main: '#2d5be6',
      dark: '#2652db',
    },
    secondary: {
      '100': '#FDF1E2',
      '200': '#FBDEBC',
      '300': '#F8CB96',
      '400': '#F5B870', //light
      '500': '#F2A549', //main
      '600': '#E08110', //dark
      '700': '#98580B',
      '800': '#512F06',
      '900': '#090601',
      light: '#F5B870', //400
      main: '#F2A549', //500
      dark: '#E08110', //600
    },
    info: {
      light: '#E0EAFF', //100
      main: '#6172F3', //500
      dark: '#444CE7', //600
    },
    warning: {
      light: '#FDB022', //400
      main: '#F79009', //500
      dark: '#DC6803', //600
    },
    error: {
      light: '#FEE4E2', //100
      main: '#F04438', //500
      dark: '#D92D20', //600
    },
    success: {
      light: '#D1FADF', //100
      main: '#12B76A', //500
      dark: '#039855', //600
    },
    grey: {
      '50': '#F9FAFB',
      '100': '#F3F4F6',
      '200': '#E5E7EB',
      '300': '#D1D5DB',
      '400': '#9CA3AF',
      '500': '#6B7280',
      '600': '#4B5563',
      '700': '#374151',
      '800': '#1F2937',
      '900': '#111827',
      A200: '#E5E5E5',
    },
    yellow: {
      '50': '#FFEFC2',
      '100': '#FFD153',
    },
    blue: {
      '200': '#DFE8F6',
      '300': '#4C87F9',
      '400': '#88AFFB',
      '500': '#115FF8',
      '600': '#10194C',
      '700': '#0A2E47',
    },
    green: {
      '200': '#ECFDF3',
      '300': '#BCEDC7',
      '400': '#7ADC8F',
    },
    pink: {
      '200': '#FCF4F5',
      '300': '#F9E9EC',
      '400': '#FE97E0',
    },
    violet: {
      '300': '#CAB9FC',
      '400': '#9574F9',
    },
    black: {
      '50': '#F6F6F6',
      '100': '#B6C0BF',
      A100: '#97A7AF',
      '200': '#E9E9E9',
      '500': '#2C2C2C',
    },
  },
});

const greyBorder = `1px solid ${theme.palette.grey[200]}`;
const whiteBorder = `2px solid ${theme.palette.common.white}`;

export const appTheme = createTheme(theme, {
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      fontStyle: 'normal',
      color: theme.palette.grey[900],
    },
    h1: {
      fontWeight: 800,
      fontSize: '96px',
      lineHeight: '120%',
      letterSpacing: 0,
      fontFamily: 'Inter',
      color: theme.palette.grey[900],
    },
    h2: {
      fontWeight: 800,
      fontSize: '60px',
      lineHeight: '120%',
      letterSpacing: 0,
      fontFamily: 'Inter',
      color: theme.palette.grey[900],
    },
    h3: {
      fontWeight: 800,
      fontSize: '48px',
      lineHeight: '120%',
      letterSpacing: 0,
      fontFamily: 'Inter',
      color: theme.palette.grey[900],
    },
    h4: {
      fontWeight: 800,
      fontSize: '36px',
      lineHeight: '120%',
      letterSpacing: 0,
      fontFamily: 'Inter',
      color: theme.palette.grey[900],
    },
    h5: {
      fontWeight: 800,
      fontSize: '24px',
      lineHeight: '120%',
      letterSpacing: 0,
      fontFamily: 'Inter',
      color: theme.palette.grey[900],
    },
    h6: {
      fontWeight: 800,
      fontSize: '20px',
      lineHeight: '120%',
      letterSpacing: 0,
      fontFamily: 'Inter',
      color: theme.palette.grey[900],
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '120%',
      fontFamily: 'Inter',
      letterSpacing: 0,
      color: theme.palette.grey[900],
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '120%',
      fontFamily: 'Inter',
      letterSpacing: 0,
      color: theme.palette.grey[700],
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '150%',
      fontFamily: 'Inter',
      color: '#475467',
      letterSpacing: 0,
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '150%',
      fontFamily: 'Inter',
      letterSpacing: 0,
      color: theme.palette.grey[600],
    },
    caption: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '120%',
      fontFamily: 'Inter',
      letterSpacing: 0,
      color: theme.palette.grey[900],
    },
    overline: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      fontFamily: 'Inter',
      letterSpacing: 0,
      color: theme.palette.grey[900],
    },
    button: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: 0,
      fontFamily: 'Inter',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overscrollBehaviorX: 'none',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: '#4D7AFF',
          backgroundImage: `url(${im})`,
          backgroundSize: 'cover',

          border: 'none',
          padding: `${theme.spacing(3)} 0`,
          height: `${desktopHeaderHeight}px`,
          [theme.breakpoints.down('md')]: {
            height: `${mobileHeaderHeight}px`,
            backgroundImage: `url(${im2})`,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          padding: `18px 0`,
          [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(1.5)} 0`,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('lg')]: {
            padding: `0 80px`,
          },
          [theme.breakpoints.down('lg')]: {
            padding: `0 40px`,
          },
          [theme.breakpoints.down('md')]: {
            padding: `0 ${theme.spacing(2)}`,
          },
        },
        disableGutters: {
          [theme.breakpoints.down('lg')]: {
            padding: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          lineHeight: '16px',
          fontFamily: 'Inter',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeSmall: {
          fontSize: '12px',
          padding: `${theme.spacing(0.5)} ${theme.spacing(1.25)}`,
          height: '24px',
        },
        sizeMedium: {
          fontSize: '14px',
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          height: '32px',
        },
        sizeLarge: {
          fontSize: '14px',
          padding: `${theme.spacing(1.25)} ${theme.spacing(2.75)}`,
          height: '36px',
        },
      },
      variants: [
        {
          props: {
            variant: 'text',
          },
          style: {
            color: theme.palette.grey[900],
          },
        },
      ],
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: greyBorder,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          height: 22,
          width: 22,
          borderRadius: 50,
          border: 'none',
          lineHeight: 'unset',
        },
        dot: {
          height: 14,
          width: 14,
          borderRadius: 50,
          border: whiteBorder,
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          position: 'fixed',
          width: '100%',
          bottom: 0,
          borderTop: greyBorder,
          zIndex: 1000,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[900],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          fontFamily: 'Inter',
          background: theme.palette.common.white,
          '&:hover fieldset': {
            borderColor: `${theme.palette.grey[500]} !important`,
          },
        },
        notchedOutline: {
          transition: 'all .25s ease',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: 750,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderRadius: '8px',

          '& .MuiTableCell-head': {
            padding: '8px',
            gap: '24px',
            height: '40px',

            /* Grayscale/50 */
            backgroundColor: theme.palette.grey[50],

            /* Body 2 */
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',

            /* Grayscale/700 */
            color: theme.palette.grey[700],

            border: 'none',

            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
            },

            '&:last-of-type': {
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-body': {
            padding: '8px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingInline: 0,
          paddingBlock: '8px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: 'Inter',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            height: '1px',
            width: '4px',
            backgroundColor: 'transparent',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            background: '#8f8f8f',
            '-webkit-border-radius': '1ex',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'inherit',
          },
        },
      },
    },
  },
});
