import { Container, Grid, Typography } from '@mui/material';

export default function OppConverted() {
  return (
    <Container maxWidth="md">
      <Grid item xs={12} md={6} mt={5}>
        <Typography textAlign="center" variant="h5">
          Opportunity Already Converted to an Exclusive
        </Typography>
        <Typography textAlign="center" variant="body1" pt={2}>
          This opportunity has already been converted to an exclusive. If you need to make further updates please
          contact support@shoutt.co.
        </Typography>
      </Grid>
    </Container>
  );
}
