import { Box, CircularProgress, Container, styled } from '@mui/material';
import { IOpportunity, OpportunityType } from 'global/interfaces/opportunity';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getOpportunity } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import OpportunityPage from '../../components/opportunities/OpportunityPage';
import OppNotFound from './OppNotFound';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '72px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '32px',
  },
}));

export function EditOpportunity() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [opp, setOpp] = useState<IOpportunity>();
  const navigate = useNavigate();

  useTitle('Admin - Edit Opportunity');

  useEffect(() => {
    if (!id) return;

    getOpportunity(id)
      .then(res => setOpp(res))
      .catch(showError)
      .finally(() => setLoading(false));
  }, []);

  if (!loading && !opp) {
    return <OppNotFound />;
  }

  return (
    <>
      {opp ? (
        <OpportunityPage
          setSubmitted={() => navigate('/admin/internal-jobs?status=Approved')}
          isExternal={opp.type === OpportunityType.External}
          opportunity={opp}
        />
      ) : (
        <StyledContainer>
          <Box sx={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
            <CircularProgress />
          </Box>
        </StyledContainer>
      )}
    </>
  );
}
