import { IContactUsForm } from 'global/interfaces/IContactUsForm';
import { SchemaOf, mixed, object, string } from 'yup';

export const contactUsValidationSchema = (shouldValidateToken: boolean): SchemaOf<IContactUsForm> => {
  const baseSchema = object({
    name: string()
      .required('Name is required')
      .trim()
      .min(2, 'Minimum of 2 characters needed')
      .max(30, 'Maximum of 30 characters allowed'),
    email: string()
      .required('Email is required')
      .trim()
      .max(150, 'Maximum of 150 characters allowed')
      .email('Invalid email address'),
    description: string()
      .required('Description is required')
      .min(2, 'Minimum of 2 characters needed')
      .max(2000, 'Maximum of 2000 characters allowed'),
    subject: string()
      .required('Description is required')
      .min(2, 'Minimum of 2 characters needed')
      .max(100, 'Maximum of 100 characters allowed'),
    file: mixed().test('fileSize', 'File size must be no more than 25MB', value => {
      if (!value) return true;

      const maxSize = 25 * 1024 * 1024;
      return value.size <= maxSize;
    }),
  });

  if (shouldValidateToken) {
    return baseSchema.shape({
      token: string().required('You must complete the reCAPTCHA'),
    });
  }

  return baseSchema.shape({
    token: string().notRequired(),
  });
};
