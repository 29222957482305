import { Grid } from '@mui/material';
import MultiFileUpload from 'components/common/PortfolioFiles/MultiFileUpload';
import { FormikProps } from 'formik';
import { IFileMetadata } from 'global/interfaces/file';
import { IEditableUser } from 'global/interfaces/user';
import { useEffect, useState } from 'react';
import { savePortfolioFile } from 'services/storageService';
import { nameof } from 'ts-simple-nameof';
import {
  handleAddFile,
  handleDeleteFile,
  handleFileNameChange,
  handleMoveLeftFile,
  handleMoveRightFile,
  multiUploadFormatError,
  onUploadStatusChange,
} from 'utils/file';

interface IEditPortfolioSectionProps {
  form: FormikProps<IEditableUser>;
}

export const EditPortfolioSection = ({ form }: IEditPortfolioSectionProps) => {
  const [files, setFiles] = useState<IFileMetadata[]>(form.values.portfolioFiles);

  useEffect(() => {
    if (files != form.initialValues.portfolioFiles) {
      form.setFieldValue('portfolioFiles', files);
    }
  }, [files]);

  return (
    <Grid container spacing={0}>
      <MultiFileUpload
        error={multiUploadFormatError<IEditableUser>(
          form,
          nameof<IEditableUser>(x => x.portfolioFiles),
        )}
        files={form.values.portfolioFiles}
        onAddFile={file => handleAddFile(file, setFiles)}
        onNameChange={(id, fileName) => handleFileNameChange(id, fileName, setFiles)}
        onDelete={id => handleDeleteFile(id, setFiles)}
        onMoveLeft={id => handleMoveLeftFile(id, setFiles)}
        onMoveRight={id => handleMoveRightFile(id, setFiles)}
        onUploadStatusChange={status => onUploadStatusChange<IEditableUser>(form, status)}
        isSellingServices={form.values.isSellingServices}
        needsVettingValidation={form.values.needsVettingValidation}
        mainCategory={form.values.mainCategory}
        uploadFunc={savePortfolioFile}
      />
    </Grid>
  );
};
