import Grid from '@mui/material/Grid';
import NoFilesIcon from 'components/common/StyledIcons/NoFilesIcon';
import { Box, FormHelperText, Stack } from '@mui/material';
import { IUploadQueue } from 'global/interfaces/user';
import React, { useEffect, useState } from 'react';
import { getPreUploadFormFile } from 'utils/file';
import { IFileMetadata } from 'global/interfaces/file';
import FileUploadButton from '../Button/FileUploadButton';
import { supportedPortfolioFileTypes } from 'global/Constants/SupportedFileTypes';
import { useSearchParams } from 'react-router-dom';
import { adminParam } from 'global/constants';
import OpportunityFiles from '../PortfolioFiles/OpportunityFiles';
import UploadChip from 'components/opportunities/apply/UploadChip';

interface IMultiFileUploadProps {
  onAddFile: (file: IFileMetadata) => void;
  onDelete: (path: string) => void;
  onMoveLeft: (path: string) => void;
  onMoveRight: (path: string) => void;
  onNameChange: (path: string, fileName: string) => void;
  onUploadStatusChange: (status: boolean) => void;
  files: IFileMetadata[];
  error?: string | string[];
  isSellingServices: boolean;
  mainCategory?: string | null;
  needsVettingValidation?: boolean;
  hideHeading?: boolean;
  mt?: number;
  uploadFunc: (file: File, controller: AbortController) => Promise<IFileMetadata>;
  compactMode?: boolean;
  maxFileSize?: number;
  maxNumFiles?: number;
  helperText?: string;
  acceptedFiles?: string;
}

const defaultMaxFileSize = 8 * 1024 * 1024;

export default function OpportunityFileUpload(props: IMultiFileUploadProps): JSX.Element {
  const [uploadQueue, setUploadQueue] = useState<IUploadQueue[]>([]);
  const [invalidSizedFiles, setInvalidSizedFiles] = useState<Array<File>>([]);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    props.onUploadStatusChange(uploadQueue.length > 0);
  }, [uploadQueue.length]);

  const onNewFilesSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newFiles: FileList | null = e.target.files;

    if (newFiles === null || newFiles.length === 0) {
      return;
    }

    const filesArr = Array.from(newFiles);
    const invalidFiles = validateFileSizes(filesArr);
    setInvalidSizedFiles(invalidFiles);

    if (invalidFiles.length > 0) {
      return;
    }

    const newFilesQueue = filesArr.map(x => getPreUploadFormFile(x));
    setUploadQueue(currentQueue => [...currentQueue, ...newFilesQueue]);
  };

  const onUploadComplete = (tempId: string, response: IFileMetadata): void => {
    const isNotCancelled = uploadQueue.some(x => x.tempId == tempId);
    if (isNotCancelled) {
      setUploadQueue(currentQueue => currentQueue.filter(x => x.tempId !== tempId));
      props.onAddFile(response);
    }
  };

  const onUploadCancel = (tempId: string): void => {
    setUploadQueue(currentQueue => currentQueue.filter(x => x.tempId !== tempId));
  };

  return (
    <React.Fragment>
      <FileUploadButton
        multiple
        accept={props.acceptedFiles ?? Object.keys(supportedPortfolioFileTypes).join(', ')}
        disabled={searchParams.has(adminParam)}
        description={<></>}
        onImageChange={onNewFilesSelect}
        compact={props.compactMode}
      />
      {props.error && typeof props.error === 'string' && (
        <Grid sx={{ p: 1, paddingLeft: 0 }} item xs={12}>
          <FormHelperText error>{props.error}</FormHelperText>
        </Grid>
      )}
      {invalidSizedFiles.length > 0 && (
        <FormHelperText error>
          {invalidSizedFiles.map(
            (f: File, i: number) =>
              `${f.name} ${i !== invalidSizedFiles.length - 1 ? ', ' : ''} ${f.length > 1 ? 'are' : 'is'} more than ${
                defaultMaxFileSize / 1024 / 1024
              }MB!`,
          )}
        </FormHelperText>
      )}
      {invalidSizedFiles.length == 0 && props.helperText && <FormHelperText error>{props.helperText}</FormHelperText>}
      <Box mt={1.5}>
        <OpportunityFiles files={props.files} onDelete={props.onDelete} />
      </Box>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {uploadQueue.map((item: IUploadQueue, i: number) => (
          <React.Fragment key={i}>
            <UploadChip
              upload={item}
              onUploadCancel={onUploadCancel}
              onUploadComplete={onUploadComplete}
              uploadFunc={props.uploadFunc}
            />
          </React.Fragment>
        ))}
      </Stack>
      {props.files?.length === 0 && uploadQueue.length === 0 && !props.hideHeading && props.hideHeading && (
        <NoFilesIcon isSellingServices={true} />
      )}
    </React.Fragment>
  );
}

function validateFileSizes(files: File[]): Array<File> {
  return files.filter(f => f.size > defaultMaxFileSize);
}
