import { useTitle } from 'utils/router';

import {
  Box,
  BoxProps,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import HeroSection, { BannerStack } from 'components/info/Hero';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';

import contracttilt from 'assets/images/lps/createcontract_tilt.svg';
import stripelogo from 'assets/images/lps/stripelogo.png';
import innovatelogo from 'assets/images/lps/innovatelogo.png';
import microsoftlogo from 'assets/images/lps/microsoftlogo.png';
import personIm from 'assets/images/lps/personimage4.webp';
import invoiceIm from 'assets/images/lps/img-bg-invoice-cta.jpg';

import { useNavigate } from 'react-router-dom';
import Talent from './buyer/Talent';

interface StyledFeatureBoxProps extends BoxProps {
  isMobile?: boolean;
}

const StyledLogoImage = styled('img')(({ theme }) => ({
  height: '60px',
  [theme.breakpoints.down('lg')]: {
    height: '50px',
  },
  [theme.breakpoints.down('md')]: {
    height: '40px',
  },
}));

const StyledImBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  padding: '16px',
  borderRadius: '12px',
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#F17522',
  marginRight: '8px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

const StyledFeatureBox = styled(Box, {
  shouldForwardProp: prop => prop != 'isMobile',
})<StyledFeatureBoxProps>(() => ({
  borderRadius: '12px',
  boxShadow: '0px 1px 3px 0px #00000026',
  backgroundColor: '#FFFFFF',
  display: 'flex',

  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  cursor: 'pointer',
}));

const StyledInvoiceBox = styled(Box)(() => ({
  display: 'flex',
  padding: '48px 32px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}));

const StyledImage = styled('img')(() => ({
  position: 'absolute',
  top: '-20px',
  left: '40px',
  height: '115%',
}));

const FeatureTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '140%',
  fontSize: '16px',
  color: theme.palette.common.white,
  fontWeight: 500,
}));

const StyledArrowForwardIcon = styled(ArrowForwardIcon)(() => ({
  width: '24px',
  height: '24px',
  marginTop: '4px',
  marginLeft: '12px',
}));

export default function Info() {
  useTitle('Shoutt | Freelancing made better');
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box sx={{ background: '#F8F7F4' }}>
        <HeroSection
          title={'For businesses who value quality, and freelancers who deliver it.'}
          subtext="Shoutt is a commission free platform for freelance work. Hire or get hired across creative, marketing and
              technical fields with next level ease and simplicity."
          minHeight={isMobile ? '320px' : '470px'}
          personImage={personIm}
        />
        <Container maxWidth="lg">
          <BannerStack direction={'column'} rowGap={{ xs: 3, md: 5, lg: 7 }} alignItems={'center'} mt={1}>
            <Grid container columnSpacing={3} rowSpacing={4}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  fontWeight={400}
                  color={'#131010'}
                  fontSize={{ xs: '16px', md: '20px', lg: '24px' }}
                  marginBottom={'16px'}
                >
                  Find Work
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <StyledFeatureBox
                      onClick={() => {
                        navigate('/opportunities');
                      }}
                    >
                      <StyledImBox>
                        <Stack direction={'column'} spacing={1.5}>
                          <JustifiedBox>
                            <Typography
                              variant="h6"
                              fontWeight={600}
                              fontSize={{ xs: '18px', md: '20px' }}
                              lineHeight={'150%'}
                              minHeight={{ xs: undefined, md: '60px' }}
                            >
                              Explore live opportunities
                            </Typography>
                            <StyledArrowForwardIcon />
                          </JustifiedBox>
                          <Typography variant="body2" color={'#101828'}>
                            Check out our range of 1000+ curated freelancer opportunities each month, all less than 7
                            days old.
                          </Typography>
                        </Stack>
                      </StyledImBox>
                    </StyledFeatureBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledFeatureBox
                      onClick={() => {
                        navigate('/signup');
                      }}
                    >
                      <StyledImBox>
                        <Stack direction={'column'} spacing={1.5}>
                          <JustifiedBox>
                            <Typography
                              variant="h6"
                              fontWeight={600}
                              fontSize={{ xs: '18px', md: '20px' }}
                              lineHeight={'150%'}
                              minHeight={{ xs: undefined, md: '60px' }}
                            >
                              Get listed on our marketplace
                            </Typography>
                            <StyledArrowForwardIcon />
                          </JustifiedBox>
                          <Typography variant="body2" color={'#101828'}>
                            Create a free profile and get listed so clients can find and contact you directly, no
                            bidding required.
                          </Typography>
                        </Stack>
                      </StyledImBox>
                    </StyledFeatureBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  fontWeight={400}
                  color={'#131010'}
                  fontSize={{ xs: '16px', md: '20px', lg: '24px' }}
                  marginBottom={'16px'}
                >
                  Find Talent
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <StyledFeatureBox
                      onClick={() => {
                        navigate('/newjob');
                      }}
                    >
                      <StyledImBox>
                        <Stack direction={'column'} spacing={1.5}>
                          <JustifiedBox>
                            <Typography
                              variant="h6"
                              fontWeight={600}
                              fontSize={{ xs: '18px', md: '20px' }}
                              lineHeight={'150%'}
                              minHeight={{ xs: undefined, md: '60px' }}
                            >
                              Post a new opportunity
                            </Typography>
                            <StyledArrowForwardIcon />
                          </JustifiedBox>
                          <Typography variant="body2" color={'#101828'}>
                            Post your opportunity and chat to qualified freelancers. No GPT pitches, no drama. Just pure
                            quality.
                          </Typography>
                        </Stack>
                      </StyledImBox>
                    </StyledFeatureBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledFeatureBox
                      onClick={() => {
                        navigate('/talent');
                      }}
                    >
                      <StyledImBox>
                        <Stack direction={'column'} spacing={1.5}>
                          <JustifiedBox>
                            <Typography
                              variant="h6"
                              fontWeight={600}
                              fontSize={{ xs: '18px', md: '20px' }}
                              lineHeight={'150%'}
                              minHeight={{ xs: undefined, md: '60px' }}
                            >
                              Explore vetted talent
                            </Typography>
                            <StyledArrowForwardIcon />
                          </JustifiedBox>
                          <Typography variant="body2" color={'#101828'}>
                            Browse and search our range of creative, marketing and technical talent with ease.
                          </Typography>
                        </Stack>
                      </StyledImBox>
                    </StyledFeatureBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              width={'100%'}
              marginTop={{ xs: '30px', md: '50px' }}
              sx={{
                backgroundImage: `url(${invoiceIm})`,
                backgroundSize: isMobile ? 'auto 100%' : 'cover',
                backgroundPosition: 'right',
                borderRadius: '18px',
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} md={5} position={'relative'}>
                  <StyledImage src={contracttilt} />
                </Grid>
                <Grid item xs={12} md={7}>
                  <StyledInvoiceBox>
                    <Typography
                      variant="h4"
                      fontSize={{ xs: '24px', md: '30px', lg: '36px' }}
                      fontWeight={500}
                      maxWidth={'600px'}
                      color={theme.palette.common.white}
                    >
                      Invoices, payment protection and contracts - all rolled into one{' '}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: '16px', lg: '18px' }}
                      mt={{ xs: '20px', md: '36px' }}
                      mb={{ xs: '20px', md: '36px' }}
                      color={theme.palette.common.white}
                    >
                      Our nifty solution for safely handling transactions means both parties can focus on getting great
                      work done, no stress!
                    </Typography>
                    <Grid container columnSpacing={4}>
                      <Grid item xs={12} md={6}>
                        <List disablePadding>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">
                                Issue and pay invoices with a range of methods
                              </FeatureTypography>
                            </ListItemText>
                          </ListItem>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">Secure two way escrow protection</FeatureTypography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <List disablePadding>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">
                                Contracts with clear cut terms to avoid headaches
                              </FeatureTypography>
                            </ListItemText>
                          </ListItem>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">100% commission free</FeatureTypography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </StyledInvoiceBox>
                </Grid>
              </Grid>
            </Box>
            <Box
              width={'100%'}
              alignContent={'center'}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
              mt={4}
            >
              <Typography
                variant="h3"
                fontSize={{ xs: '24px', md: '28px', lg: '36px' }}
                color={theme.palette.common.black}
                maxWidth={'900px'}
                fontWeight={500}
                textAlign={'center'}
                lineHeight={{ xs: '32px', md: '34px', lg: '44px' }}
                mb={{ xs: 1, md: 5 }}
              >
                Check out some of our awesome freelance talent
              </Typography>
              <Talent />
            </Box>
            <Stack direction={'column'} alignItems="center" spacing={{ xs: 4, md: 6 }} mb={6} mt={-1}>
              <Typography
                variant="h3"
                fontSize={{ xs: '24px', md: '28px', lg: '36px' }}
                color={theme.palette.common.black}
                maxWidth={'900px'}
                fontWeight={500}
                textAlign={'center'}
                lineHeight={{ xs: '32px', md: '34px', lg: '44px' }}
              >
                We’re proud to partner with
              </Typography>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4, md: 8 }} alignItems={'center'}>
                <StyledLogoImage src={microsoftlogo} />
                <StyledLogoImage src={innovatelogo} />
                <StyledLogoImage src={stripelogo} />
              </Stack>
            </Stack>
          </BannerStack>
        </Container>
      </Box>
    </>
  );
}
