import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ApplyInstructionsType,
  ApplyStageType,
  IOpportunity,
  OpportunityLocationType,
  OpportunityType,
} from 'global/interfaces/opportunity';

import { pushToDataLayer } from 'utils/tagHelper';
import { viewOpportunity } from 'global/constants';
import { opportunityViewed } from 'services/opportunityService';
import { AccessTime } from '@mui/icons-material';
import ShareButton from 'components/ShareButton';
import { AuthContext } from 'contexts/AuthContext';

import { UserRole } from 'global/enums/userRole';
import React, { useContext } from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface StyledAccordionProps extends AccordionProps {
  open?: boolean;
}

const StyledAccordion = styled(Accordion)<StyledAccordionProps>(({ theme, open }) => ({
  marginBottom: '16px',

  '&.MuiPaper-root': {
    borderRadius: '12px',
    padding: '16px',
    borderColor: theme.palette.grey[200],

    '&:before': {
      display: 'none',
    },
  },

  '&:last-of-type': {
    marginBottom: 0,
  },

  '& .MuiAccordionSummary-root': {
    cursor: open ? 'default' : 'pointer',
    '&.Mui-expanded, &.Mui-expanded:hover': {
      cursor: open ? 'default !important' : 'pointer',
    },

    '&:hover.Mui-expanded': {
      cursor: open ? 'default !important' : 'pointer',
    },
  },

  [theme.breakpoints.up('md')]: {
    '&.MuiPaper-root': {
      '&:hover': {
        borderColor: !open ? '#4B56D8' : '#10182833',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    '&.MuiPaper-root': {
      borderRadius: '8px',
      padding: '10px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
}));

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  'svg[data-testid="ExpandMoreIcon"]': {
    display: 'inline-block',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandMoreIcon"]': {
    display: 'none',
  },
  'svg[data-testid="ExpandLessIcon"]': {
    display: 'none',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandLessIcon"]': {
    display: 'inline-block',
  },
  [theme.breakpoints.down('md')]: {
    padding: '8px',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '8px',
    paddingBottom: '24px',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: '24px',

  backgroundColor: theme.palette.black[200],
  borderRadius: '16px',
  color: theme.palette.black[500],
  cursor: 'pointer',
  padding: '4px 4px',
}));

const StyledAssistedChip = styled(StyledChip)(() => ({
  backgroundColor: '#D3E5FD',
}));

const StyledShouttChip = styled(StyledChip)(() => ({
  backgroundColor: '#DEDBED',
}));

interface IOpportunityAccordionProps {
  loggedIn?: boolean;
  opportunity: IOpportunity;
  expanded?: boolean;
  children: React.ReactNode;
  forceMobile?: boolean;
  extraHeaderInfo?: React.ReactNode;
  updateOpportunity?: (opportunity: IOpportunity) => void;
  showTrackingIcon?: boolean;
  locked?: boolean;
}

export default function OpportunityAccordion({
  opportunity,
  loggedIn,
  expanded,
  children,
  forceMobile,
  extraHeaderInfo,
  updateOpportunity,
  showTrackingIcon,
  locked,
}: IOpportunityAccordionProps): JSX.Element {
  const theme = useTheme();
  const isMobile = forceMobile ? true : useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useContext(AuthContext);

  const hasAdminRole = authContext.user && authContext.user.roles.indexOf(UserRole.Administrator) > -1;
  const baseUrl = hasAdminRole
    ? process.env.REACT_APP_ADMIN_SHARE_OPPORTUNITY_URL
    : process.env.REACT_APP_SHARE_OPPORTUNITY_URL;
  let shareUrl = `${baseUrl}?opportunityId=${opportunity.id}`;

  if (!hasAdminRole && authContext.user?.id) {
    shareUrl = shareUrl + `&source=${authContext.user.id}`;
  } else if (!hasAdminRole) {
    shareUrl = shareUrl + `&source=nou`;
  }

  const onExpandContractOpportunity = (opportunity: IOpportunity, expanded: boolean): void => {
    if (expanded) {
      pushToDataLayer(viewOpportunity, {
        transaction_id: opportunity.id,
      });
      if (loggedIn && !locked) {
        opportunityViewed(opportunity.id);
        if (updateOpportunity && opportunity.applyStage == null) {
          opportunity.applyStage = ApplyStageType.Viewed;
          updateOpportunity(opportunity);
        }
      }
    }
  };

  return (
    <StyledAccordion
      elevation={10}
      disableGutters
      onChange={(_: any, expanded: boolean) => onExpandContractOpportunity(opportunity, expanded)}
      expanded={expanded}
      open={expanded}
    >
      <StyledAccordionSummary>
        <JustifiedBox>
          <Stack direction="column" gap={isMobile ? 0.75 : 1} width={isMobile ? '100%' : undefined}>
            <Stack direction="row" justifyContent={isMobile ? 'space-between' : 'flex-start'}>
              <Typography variant="subtitle1" fontSize={{ xs: '16px', md: '18px' }} component={'div'}>
                <span style={{ marginRight: '8px' }}>{opportunity.title}</span>
                {opportunity.isFree && !isMobile && (
                  <Tooltip
                    arrow
                    enterTouchDelay={0}
                    title="You can apply for this opportunity with a free account. You will need to pass our screening process to complete your application."
                    componentsProps={{
                      tooltip: {
                        sx: {
                          maxWidth: '320px',
                          borderRadius: '12px',
                        },
                      },
                    }}
                  >
                    <StyledChip
                      label={<Typography variant={'caption'}>Free to Apply</Typography>}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                  </Tooltip>
                )}

                {opportunity.type == OpportunityType.Shoutt && !isMobile && (
                  <StyledShouttChip
                    label={<Typography variant={'caption'}># Shoutt Exclusive</Typography>}
                    size="small"
                  />
                )}

                {opportunity.type == OpportunityType.External && !isMobile && (
                  <>
                    {opportunity.isAssisted ? (
                      <Tooltip
                        arrow
                        enterTouchDelay={0}
                        title="We have sourced this opportunity from an external site. We collect the applications and forward them to the job poster on your behalf. They will respond to you directly if they are interested."
                        componentsProps={{
                          tooltip: {
                            sx: {
                              maxWidth: '320px',
                              borderRadius: '12px',
                            },
                          },
                        }}
                      >
                        <StyledAssistedChip
                          label={<Typography variant={'caption'}># Shoutt Assisted</Typography>}
                          size="small"
                        />
                      </Tooltip>
                    ) : (
                      <>
                        {opportunity.applyInstructionsType === ApplyInstructionsType.Text ? (
                          <StyledChip
                            label={<Typography variant={'caption'}>Apply via Email</Typography>}
                            size="small"
                          />
                        ) : (
                          <Tooltip
                            arrow
                            enterTouchDelay={0}
                            title="We have sourced this opportunity from an external site. We spend 40+ hours a week curating opportunities from over 30 sources so you don't have to."
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  maxWidth: '320px',
                                  borderRadius: '12px',
                                },
                              },
                            }}
                          >
                            <StyledChip label={<Typography variant={'caption'}>External</Typography>} size="small" />
                          </Tooltip>
                        )}
                      </>
                    )}
                  </>
                )}
                {!isMobile && !forceMobile && <>{locked ? <LockIcon /> : <ShareButton url={shareUrl} />}</>}
              </Typography>
              {isMobile && (
                <>
                  {!forceMobile ? (
                    <Stack mt="-2px">{locked ? <LockIcon /> : <ShareButton url={shareUrl} />}</Stack>
                  ) : (
                    <Typography
                      variant="body2"
                      color={theme.palette.grey[500]}
                      pl={1.5}
                      minWidth={'60px'}
                      component={'div'}
                    >
                      {opportunity.agoDesc}
                    </Typography>
                  )}
                </>
              )}
            </Stack>

            {opportunity.type == OpportunityType.Shoutt && isMobile && (
              <Box>
                <StyledShouttChip
                  label={<Typography variant={'caption'}># Shoutt Exclusive</Typography>}
                  size="small"
                />
                {opportunity.isFree && (
                  <StyledChip
                    label={<Typography variant={'caption'}>Free to Apply</Typography>}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Box>
            )}

            {opportunity.type == OpportunityType.External && isMobile && (
              <Box>
                {opportunity.isAssisted ? (
                  <StyledAssistedChip
                    label={<Typography variant={'caption'}># Shoutt Assisted</Typography>}
                    size="small"
                  />
                ) : (
                  <>
                    {opportunity.applyInstructionsType === ApplyInstructionsType.Text ? (
                      <StyledChip label={<Typography variant={'caption'}>Apply via Email</Typography>} size="small" />
                    ) : (
                      <StyledChip label={<Typography variant={'caption'}>External</Typography>} size="small" />
                    )}
                  </>
                )}
                {opportunity.isFree && (
                  <StyledChip
                    label={<Typography variant={'caption'}>Free to Apply</Typography>}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Box>
            )}

            <Typography
              variant="body2"
              color={theme.palette.grey[500]}
              component={Box}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {opportunity.categories.map((category: string, index: number) => (
                <React.Fragment key={index}>
                  <span style={{ marginRight: '10px' }}>{category}</span>
                  <span style={{ marginRight: '10px' }}>{'•'}</span>
                </React.Fragment>
              ))}
              {opportunity.locationType == OpportunityLocationType.Remote ? (
                <span style={{ marginRight: '10px' }}>Remote (Worldwide)</span>
              ) : (
                <span style={{ marginRight: '10px' }}>{opportunity.location}</span>
              )}
              {opportunity.budget && (
                <>
                  <span style={{ marginRight: '10px' }}>{'•'}</span>
                  <span style={{ marginRight: '10px' }}>{opportunity.budget}</span>
                </>
              )}
              {extraHeaderInfo}
            </Typography>

            {isMobile && !forceMobile && (
              <JustifiedBox paddingRight={'4px'}>
                <Stack direction={'row'} gap={0.5} minWidth={'100px'} mt={0.5}>
                  <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
                  <Typography variant="body2" color={theme.palette.grey[500]}>
                    {opportunity.agoDesc}
                  </Typography>
                </Stack>
                {loggedIn && showTrackingIcon && (
                  <OpportunityIcon opportunity={opportunity} isMobile={isMobile && !forceMobile} />
                )}
              </JustifiedBox>
            )}
          </Stack>
          {!isMobile && (
            <Stack direction={'row'} gap={0.5} ml={1}>
              <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
              <Typography variant="body2" color={theme.palette.grey[500]} noWrap>
                {opportunity.agoDesc}
              </Typography>
              {loggedIn && showTrackingIcon && (
                <OpportunityIcon opportunity={opportunity} isMobile={isMobile && !forceMobile} />
              )}
            </Stack>
          )}
        </JustifiedBox>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <Stack direction="column" spacing={0} width="100%" gap={3}>
          <Typography
            variant={isMobile ? 'body2' : 'body1'}
            whiteSpace="break-spaces"
            sx={{ filter: locked ? 'blur(4px)' : undefined, userSelect: locked ? 'none' : undefined }}
          >
            {opportunity.description}
          </Typography>
          <Grid container gap={2}>
            {children}
          </Grid>
        </Stack>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

function LockIcon() {
  const theme = useTheme();

  return (
    <Tooltip
      disableFocusListener
      title="This opportunity is locked. To view and apply you will need our premium subscription. For more details please see our pricing page."
      arrow
      enterTouchDelay={300}
    >
      <Box component={'span'} display={'inline-block'} position={'relative'} sx={{ marginLeft: '6px', top: '3px' }}>
        <LockOutlinedIcon sx={{ color: theme.palette.grey[500] }} />
      </Box>
    </Tooltip>
  );
}

function OpportunityIcon({ opportunity, isMobile }: { opportunity: IOpportunity; isMobile: boolean }) {
  const theme = useTheme();

  return (
    <>
      {opportunity.applyStage == ApplyStageType.Applied && (
        <Tooltip
          disableFocusListener
          title="You applied to this opportunity"
          arrow
          enterTouchDelay={300}
          placement={isMobile ? 'left' : 'bottom'}
        >
          <CheckCircleOutlineOutlinedIcon
            sx={{
              marginLeft: '20px',
              color:
                opportunity.applyStage == ApplyStageType.Applied ? theme.palette.success.main : theme.palette.grey[400],
            }}
          />
        </Tooltip>
      )}
      {opportunity.applyStage == ApplyStageType.Viewed && (
        <Tooltip
          disableFocusListener
          title="You viewed this opportunity"
          arrow
          enterTouchDelay={300}
          placement={isMobile ? 'left' : 'bottom'}
        >
          <VisibilityOutlinedIcon
            sx={{
              marginLeft: '20px',
              color: theme.palette.grey[400],
            }}
          />
        </Tooltip>
      )}
      {opportunity.applyStage == ApplyStageType.NotInterested && (
        <Tooltip
          disableFocusListener
          title="You marked this opportunity as not interested"
          arrow
          enterTouchDelay={300}
          placement={isMobile ? 'left' : 'bottom'}
        >
          <BlockOutlinedIcon
            sx={{
              marginLeft: '20px',
              color: theme.palette.error.main,
            }}
          />
        </Tooltip>
      )}
      {opportunity.applyStage === null && (
        <VisibilityOutlinedIcon
          sx={{
            marginLeft: '20px',
            color: 'transparent',
          }}
        />
      )}
    </>
  );
}
